import React from 'react'
import { Box } from '@mui/material'

function NotVerified() {
  return (
    <Box>
      Not Verified
    </Box>
  )
}

export default NotVerified
