/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  metaData: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setMetaData: (state, action) => {
      state.metaData = action.payload
    },
    addSavedFriend: (state, action) => {
      const metaData = { ...state.metaData }
      const newFriends = metaData.friends
      newFriends.push(action.payload)
      state.metaData = { ...metaData, friends: newFriends }
    },
    removeSavedFriend: (state, action) => {
      const metaData = { ...state.metaData }
      const newFriends = metaData.friends.filter((friend) => friend.handle !== action.payload)
      state.metaData = { ...metaData, friends: newFriends }
    },
  },
})

export const {
  setMetaData,
  addSavedFriend,
  removeSavedFriend,
} = userSlice.actions

export const selectMetaData = (state) => state.user.metaData

export default userSlice.reducer
