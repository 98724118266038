import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import registerReducer from '../containers/register/registerSlice'
import userReducer from '../containers/user/userSlice'
import partyReducer from '../containers/cockpit/partySlice'
import playerReducer from '../containers/cockpit/playerSlice'
import friendsReducer from '../containers/cockpit/friendsSlice'
import mainReducer from './mainSlice'

const store = configureStore({
  reducer: {
    counter: counterReducer,
    friends: friendsReducer,
    main: mainReducer,
    party: partyReducer,
    player: playerReducer,
    register: registerReducer,
    user: userReducer,
  },
})

export default store
