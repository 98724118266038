/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  handleError: {
    error: false,
    errorMessage: '',
  },
  emailError: {
    error: false,
    errorMessage: '',
  },
  passwordError: {
    error: false,
    errorMessage: '',
  },
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setHandleError: (state, action) => {
      state.handleError = {
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      }
    },
    setEmailError: (state, action) => {
      state.emailError = {
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      }
    },
    setPasswordError: (state, action) => {
      state.passwordError = {
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      }
    },
    resetErrors: (state) => {
      state.handleError = {
        error: false,
        errorMessage: '',
      }
      state.emailError = {
        error: false,
        errorMessage: '',
      }
      state.passwordError = {
        error: false,
        errorMessage: '',
      }
    },
  },
})

export const {
  setHandleError, setEmailError, resetErrors, setPasswordError,
} = registerSlice.actions

export const selectEmailError = (state) => state.register.emailError
export const selectHandleError = (state) => state.register.handleError
export const selectPasswordError = (state) => state.register.passwordError

export default registerSlice.reducer
