/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isTokenFound: false,
}

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setTokenFound: (state, action) => {
      state.isTokenFound = action.payload
    },
  },
})

export const {
  setTokenFound,
} = mainSlice.actions

export const selectIsTokenFound = (state) => state.main.isTokenFound

export default mainSlice.reducer
