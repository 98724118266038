import FavoriteIcon from '@mui/icons-material/Favorite'
import {
  Avatar,
  Box, IconButton,
  List, ListItem,
  ListItemAvatar,
  ListItemButton, ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import { getPlayerByHandle } from '../../utils/api'
import { deletePlayer } from '../../utils/firebase/player'

function CockpitPlayers({
  savedPlayers,
  isSelectedPlayerAvailable,
  selectedPlayer,
  commonBorderStyle,
}) {
  return (
    <Grid
      xs={12}
      md
      sx={{
        ...commonBorderStyle,
        borderRightWidth: 1,
      }}
    >
      <Box sx={{ px: 3 }}>
        <Typography variant="h4" gutterBottom>My saved players</Typography>
        {Object.keys(savedPlayers).length
          ? (
            <List sx={{ bgcolor: 'background.paper' }}>
              {Object.keys(savedPlayers).map((playerName) => {
                const player = savedPlayers[playerName]
                return (
                  <ListItem key={player.profile.handle} disablePadding>
                    <ListItemButton
                      selected={isSelectedPlayerAvailable && playerName === selectedPlayer.profile.handle}
                      onClick={() => getPlayerByHandle(player.profile.handle, true)}
                    >
                      <ListItemAvatar>
                        <Avatar alt={player.profile.handle} src={player.profile.image} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={player.profile.handle}
                        secondary={player.organization.name}
                      />

                    </ListItemButton>
                    <ListItemSecondaryAction onClick={() => deletePlayer(player.id, player.profile.handle)}>
                      <IconButton edge="end" aria-label="favorite">
                        <FavoriteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          )
          : (<Box>No saved players</Box>)}
      </Box>
    </Grid>
  )
}

export default CockpitPlayers
