import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import SearchIcon from '@mui/icons-material/Search'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import { addOrUpdatePlayer, deletePlayer } from '../../utils/firebase/player'
import PlayerInfoAffiliation from './PlayerInfoAffiliation'
import PlayerInfoProfile from './PlayerInfoProfile'
import PlayerInfoOrgaization from './PlayerInfoOrgaization'

function PlayerInfo({
  handlePlayerSubmit,
  isSelectedPlayerSaved,
  isSelectedPlayerAvailable,
  selectedPlayer,
  commonBorderStyle,
  sharePlayer,
}) {
  return (
    <Grid
      xs={12}
      md={6}
      sx={{
        ...commonBorderStyle,
        borderWidth: 1,
      }}
    >
      <Box sx={{
        mb: 6,
      }}
      >
        <Box
          component="form"
          noValidate
          onSubmit={handlePlayerSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            required
            id="playerHandle"
            label="Player Handle"
            name="playerHandle"
            sx={{ mx: 1 }}
          />
          <Button
            startIcon={<SearchIcon />}
            type="submit"
            variant="contained"
            sx={{ mx: 1 }}
          >
            Search
          </Button>
        </Box>
      </Box>
      {!isSelectedPlayerAvailable && !!selectedPlayer && !!selectedPlayer.error
        && (
          <Box>
            <Typography>
              {selectedPlayer.error}
            </Typography>
          </Box>
        )}
      {isSelectedPlayerAvailable && (
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Card sx={{ width: '80%' }}>
            <CardHeader
              avatar={<Avatar alt={selectedPlayer.organization.name} src={selectedPlayer.organization.image} />}
              title={selectedPlayer.organization.name}
              action={(
                <IconButton
                  onClick={
                    () => {
                      if (isSelectedPlayerSaved) {
                        deletePlayer(selectedPlayer.id, selectedPlayer.profile.handle)
                      } else {
                        addOrUpdatePlayer(selectedPlayer)
                      }
                    }
                  }
                  aria-label="favorite"
                >
                  {isSelectedPlayerSaved
                    ? (<FavoriteIcon />)
                    : (<FavoriteBorderOutlinedIcon />)}
                </IconButton>
              )}
            />
            <CardMedia
              component="img"
              height="250"
              image={selectedPlayer.profile.image}
              alt={selectedPlayer.profile.handle}
              sx={{
                objectFit: 'contain',
                background: 'radial-gradient(circle, rgba(28,60,79,0) 20%, rgba(13,38,53,1) 100%)',
              }}
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="div">
                {selectedPlayer.profile.handle}
              </Typography>
              <Grid container spacing={2}>
                <Grid md={12} lg={6} xl={7}>
                  <PlayerInfoProfile selectedPlayer={selectedPlayer} />
                </Grid>
                {(selectedPlayer.organization && selectedPlayer.organization.name.length !== 0) && (
                  <Grid md={12} lg={6} xl={5}>
                    <PlayerInfoOrgaization selectedPlayer={selectedPlayer} />
                  </Grid>
                )}

                {(selectedPlayer.affiliation.length && selectedPlayer.affiliation[0].sid) && (
                  <Grid md={12}>
                    <PlayerInfoAffiliation selectedPlayer={selectedPlayer} />
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Button onClick={() => sharePlayer(selectedPlayer)} size="small">Share</Button>
              <Button href={selectedPlayer.profile.page.url} target="_blank" size="small">
                Go to CIG profile
              </Button>
            </CardActions>
          </Card>
        </Box>
      )}
    </Grid>
  )
}

export default PlayerInfo
