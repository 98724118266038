import { Box, Rating, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'

function PlayerInfoAffiliation({
  selectedPlayer,
}) {
  return (
    <Box>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          textTransform: 'uppercase',
          color: (theme) => theme.palette.primary.light,
        }}
      >
        Affiliation
      </Typography>
      <Grid container spacing={4}>
        {selectedPlayer.affiliation.map((entry) => (
          <Grid md={12} lg={6} key={entry.name}>
            <Box>
              <Box
                component="img"
                alt={entry.name}
                src={entry.image}
              />
            </Box>
            <Typography
              component="p"
              sx={{
                fontSize: 20,
                pb: 2,
              }}
            >
              {entry.name}
            </Typography>
            <Typography
              component="p"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontSize: 12,
              }}
            >
              Spectrum Identification (SID)
            </Typography>
            <Typography component="p" sx={{ pb: 2 }}>
              {entry.sid}
            </Typography>
            <Rating name="read-only" value={parseInt(entry.stars, 10)} readOnly />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default PlayerInfoAffiliation
