import {
  collection, getDocs, query, where,
} from 'firebase/firestore'
import { getMessaging, onMessage } from 'firebase/messaging'
import { clearFoundFriends, setFoundFriend } from '../../containers/cockpit/friendsSlice'
import store from '../../redux/store'
import { db, messaging } from './base'

export const onMessageListener = () => new Promise((resolve) => {
  onMessage(messaging, (payload) => {
    resolve(payload)
  })
})

export const findFriend = async (handle) => {
  try {
    const userRef = collection(db, 'users')
    const q = query(userRef, where('handle', '==', handle))
    const querySnapshot = await getDocs(q)
    store.dispatch(clearFoundFriends())
    if (!querySnapshot.empty) {
      querySnapshot.forEach((friend) => {
        store.dispatch(setFoundFriend({
          uid: friend.data().uid,
          handle: friend.data().handle,
          token: friend.data().token,
        }))
      })
    }
  } catch (error) {
    console.error(error)
  }
}

// export const saveFriend = async (friend) => {
//   try {
//
//   } catch (error) {
//     console.error(error)
//   }
// }

export const sendPlayerInfo = async (recipientGroup, player) => {
  try {
    const message = {
      data: {
        ...player,
        test: 'test',
      },
      topic: recipientGroup,
    }

    const response = await getMessaging().send(message)

    console.log('Response', response)
  } catch (error) {
    console.error('sendPlayerInfoError:', error)
  }
}
