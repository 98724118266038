import {
  getFirestore,
}
  from 'firebase/firestore'
import { getMessaging } from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
} from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBsUzUl7dE6stv_5nCa3MEk7fQ5_8yxYXY',
  authDomain: 'player-info-overlay.firebaseapp.com',
  databaseURL: 'https://player-info-overlay-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'player-info-overlay',
  storageBucket: 'player-info-overlay.appspot.com',
  messagingSenderId: '816040201521',
  appId: '1:816040201521:web:cfb6cbe69ef682ef557410',
}

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)

export const db = getFirestore(app)

export const messaging = getMessaging(app)
