import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button, Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton, ListItemSecondaryAction,
  ListItemText, ListSubheader,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupsIcon from '@mui/icons-material/Groups'
import Grid from '@mui/material/Unstable_Grid2'
import { sendPartyInvitation } from '../../utils/api'
import { unsubscribeToParty } from '../../utils/firebase/party'
import { addSavedFriend, removeSavedFriend } from '../../utils/firebase/user'

const isUserSavedFriend = (userHandle, savedFriends) => savedFriends && !!savedFriends.find((friend) => friend.handle === userHandle)

const addAndInviteFriend = (party, friend) => {
  addSavedFriend(friend)
  if (party.uid) {
    sendPartyInvitation(friend.uid, friend.token, party.name, party.uid)
  } else {
    alert('New Party yet created')
  }
}

function CockpitFriends({
  handleFriendSubmit,
  handleCreatePartySubmit,
  foundFriends,
  party,
  savedFriends,
  commonBorderStyle,
}) {
  const dispatch = useDispatch()

  return (
    <Grid
      xs={12}
      md
      sx={{
        ...commonBorderStyle,
        borderLeftWidth: 1,
      }}
    >
      <Box sx={{ px: 3 }}>
        <Typography variant="h4" gutterBottom>My friends</Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleFriendSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextField
            required
            id="friendHandle"
            label="Friend Handle"
            name="friendHandle"
          />
          <Button
            startIcon={<SearchIcon />}
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Search
          </Button>
        </Box>
        {foundFriends
          ? (
            <List sx={{ bgcolor: 'background.paper' }}>
              {foundFriends.map((friend) => {
                const isSavedUser = isUserSavedFriend(friend.handle, savedFriends)

                return (
                  <ListItem key={friend.handle} disablePadding>
                    <ListItemButton onClick={() => addAndInviteFriend(party, friend, dispatch)}>
                      <ListItemText
                        primary={`${friend.handle}`}
                      />
                    </ListItemButton>
                    <ListItemSecondaryAction onClick={
                      () => {
                        if (isSavedUser) {
                          removeSavedFriend(friend)
                        } else {
                          addSavedFriend(friend)
                        }
                      }
                    }
                    >
                      <IconButton edge="end" aria-label="add">
                        {isSavedUser
                          ? (<FavoriteIcon />)
                          : (<FavoriteBorderOutlinedIcon />)}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </List>
          )
          : (<Box>No friend found</Box>)}
        <Divider />
        <Box>
          <Typography variant="h6" gutterBottom>My saved friends</Typography>
          {savedFriends
            ? (
              <List sx={{ bgcolor: 'background.paper' }}>
                {savedFriends.map((friend) => (
                  <ListItem key={friend.handle} disablePadding>
                    <ListItemButton
                      onClick={() => !!party.uid && sendPartyInvitation(friend.uid, friend.token, party.name, party.uid)}
                    >
                      <ListItemText
                        primary={friend.handle}
                      />
                    </ListItemButton>
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeSavedFriend(friend)}>
                        <FavoriteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>

            ) : (<Box>No saved friends</Box>)}
        </Box>
        <Divider />
        <Box>
          <Typography variant="h4" gutterBottom>My party</Typography>
          {party.uid
            ? (
              <List
                sx={{ bgcolor: 'background.paper' }}
                subheader={(
                  <ListSubheader component="div" id="list-subheader">
                    {party.name}
                  </ListSubheader>
                )}
              >
                {party.members.map((partyMember) => (
                  <ListItem key={partyMember.handle} disablePadding>
                    <ListItemButton>
                      <ListItemText
                        primary={partyMember.handle}
                      />
                    </ListItemButton>
                    <ListItemSecondaryAction onClick={() => unsubscribeToParty(party.uid, partyMember)}>
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>

            ) : (
              <Box
                component="form"
                noValidate
                onSubmit={handleCreatePartySubmit}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <TextField
                  required
                  id="partyName"
                  label="Party Name"
                  name="partyName"
                />
                <Button
                  startIcon={<GroupsIcon />}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Create Party
                </Button>
              </Box>
            )}
        </Box>
      </Box>
    </Grid>
  )
}

export default CockpitFriends
