import React from 'react'
import {
  AppBar, Box, Toolbar, Typography,
} from '@mui/material'
import logo from '../assets/logo192.png'

function AppFrame({ children }) {
  return (
    <>
      <AppBar position="relative" color="primary" enableColorOnDark>
        <Toolbar>
          <Box
            component="img"
            sx={{ height: 42, paddingRight: 1 }}
            alt="Logo"
            src={logo}
          />
          <Typography variant="h6" color="inherit" noWrap>
            Citizen Finder
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Box
          sx={{
            pt: 8,
            px: 6,
            pb: 6,
          }}
        >
          {children}
        </Box>
      </main>
    </>
  )
}

export default AppFrame
