import { Box, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'

function PlayerInfoProfile({ selectedPlayer }) {
  return (
    <Box sx={{ pb: 4 }}>
      <Grid container spacing={2}>
        <Grid xl={6} lg={12}>
          <Stack component="" alignItems="center" direction="row" gap={1}>
            <Box
              component="img"
              height={22}
              position="relative"
              top="-2px"
              alt={selectedPlayer.profile.badge}
              src={selectedPlayer.profile.badge_image}
            />
            <Typography component="span">{selectedPlayer.profile.badge}</Typography>
          </Stack>
        </Grid>
        <Grid xl={6} lg={12}>
          <Typography
            component="p"
            sx={{
              color: (theme) => theme.palette.primary.light,
              fontSize: 12,
            }}
          >
            Enlisted
          </Typography>
          <Typography component="p">
            {new Date(selectedPlayer.profile.enlisted).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })}
          </Typography>
        </Grid>
        {selectedPlayer.location && (
          <Grid xl={6} lg={12}>
            <Typography
              component="p"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontSize: 12,
              }}
            >
              Location
            </Typography>
            <Typography component="p">
              {`${selectedPlayer.profile.location.country}, ${selectedPlayer.profile.location.region}`}
            </Typography>
          </Grid>
        )}
        {selectedPlayer.profile.fluency && selectedPlayer.profile.fluency.length !== 0 && (
          <Grid xl={6} lg={12}>
            <Typography
              component="p"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontSize: 12,
              }}
            >
              Fluency
            </Typography>
            <Typography component="p">
              {selectedPlayer.profile.fluency.map((fluency, index) => (
                <Typography component="span" key={fluency}>
                  <Typography component="span">{fluency}</Typography>
                  {selectedPlayer.profile.fluency.length - 1 !== index && (<Typography component="span">, </Typography>)}
                </Typography>
              ))}
            </Typography>
          </Grid>
        )}
        {selectedPlayer.profile.bio && (
          <Grid xs={12}>
            <Typography
              component="p"
              sx={{
                color: (theme) => theme.palette.primary.light,
                fontSize: 12,
              }}
            >
              Bio
            </Typography>
            <Typography component="p">{selectedPlayer.profile.bio}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default PlayerInfoProfile
