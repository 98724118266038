import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import routes from './containers/routes'
import Login from './containers/Login'
import Register from './containers/register/Register'
import Cockpit from './containers/cockpit/Cockpit'
import theme from './theme'
import NotVerified from './containers/NotVerified'
import AppFrame from './components/AppFrame'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppFrame>
        <BrowserRouter>
          <Routes>
            <Route path={routes.login} element={<Login />} />
            <Route path={routes.register} element={<Register />} />
            <Route path={routes.user} element={<Cockpit />} />
            <Route path={routes.emailNotVerified} element={<NotVerified />} />
          </Routes>
        </BrowserRouter>
      </AppFrame>
    </ThemeProvider>
  )
}

export default App
