import {
  addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where,
} from 'firebase/firestore'
import {
  addOrUpdatePlayer as addOrUpdatePlayerAction, removePlayer,
  setSavedPlayerListAfterLoading,
} from '../../containers/cockpit/playerSlice'
import store from '../../redux/store'
import { auth, db } from './base'

export const loadSavedPlayers = async () => {
  const playersRef = collection(db, 'players')
  const querySnapshot = await getDocs(query(playersRef, where('ownerId', '==', auth.currentUser.uid)))
  if (!querySnapshot.empty) {
    const playerList = {}
    querySnapshot.forEach((player) => {
      playerList[player.data().profile.handle] = { ...player.data(), id: player.id }
    })
    store.dispatch(setSavedPlayerListAfterLoading(playerList))
  }
}

export const addOrUpdatePlayer = async (playerData) => {
  try {
    const playersRef = collection(db, 'players')
    const q = query(
      playersRef,
      where('ownerId', '==', auth.currentUser.uid),
      where('profile.handle', '==', playerData.profile.handle),
    )
    const querySnapshot = await getDocs(q)

    if (querySnapshot.empty) {
      const docRef = await addDoc(collection(db, 'players'), {
        ...playerData,
        ownerId: auth.currentUser.uid,
      })
      store.dispatch(addOrUpdatePlayerAction({ ...playerData, id: docRef.id }))
    } else {
      querySnapshot.forEach((document) => {
        const docRef = doc(db, 'players', document.id)
        updateDoc(docRef, {
          ...playerData,
        })
        store.dispatch(addOrUpdatePlayerAction({ ...playerData, id: document.id }))
      })
    }
  } catch (error) {
    console.error(error)
  }
}

export const deletePlayer = async (playerId, handle) => {
  try {
    await deleteDoc(doc(db, 'players', playerId))
    store.dispatch(removePlayer(handle))
  } catch (error) {
    console.error(error)
  }
}
