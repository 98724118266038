import { createTheme } from '@mui/material/styles'

const themePalette = {
  mode: 'dark',
  primary: {
    main: '#103246',
    light: '#128feb',
    dark: '#0d2635',
  },
  secondary: {
    main: '#c88927',
  },
  background: {
    default: '#0d2635',
    paper: '#103246',
  },
  divider: '#85d0ff',
}

// A custom theme for this app
const theme = createTheme({
  palette: themePalette,
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: themePalette.primary.light,
          // eslint-disable-next-line max-len
          maskImage: 'none, none, url("data:image/svg+xml,%3Csvg xmlns=\\"http://www.w3.org/2000/svg\\" fill=\\"%23000\\" preserveAspectRatio=\\"none\\" viewBox=\\"0 0 24 24\\"%3E%3Cpath d=\\"M24 0v4.7a8 8 0 01-2.3 5.6L10.3 21.7A8 8 0 014.7 24H0V0h24z\\"/%3E%3C/svg%3E"), none, linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)), linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)), linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)), linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)), linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0))',
          maskSize: '0px 0px, 0px 0px, 16px 16px, 0px 0px, calc(100% + 2px) 1px, 1px calc(100% + 2px), calc(100% + 2px) calc(100% - 15px), 1px calc(100% - 15px), calc(100% - 15px) 33px',
          maskPosition: 'left top, right top, right bottom, left bottom, -1px top, left -1px, -1px -1px, right -1px, -1px bottom',
          maskRepeat: 'no-repeat',
          '&:hover': {
            backgroundColor: themePalette.divider,
          },
        },
        text: {
          color: themePalette.primary.light,
          '&:hover': {
            color: themePalette.divider,
          },
        },
      },
    },
  },
})

export default theme
