import {
  createUserWithEmailAndPassword,
  sendEmailVerification, sendPasswordResetEmail,
  signInWithEmailAndPassword, signOut,
  updateProfile,
} from 'firebase/auth'
import {
  arrayRemove, arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where,
} from 'firebase/firestore'
import { getToken as getFCMToken } from 'firebase/messaging'
import { resetErrors, setEmailError, setHandleError } from '../../containers/register/registerSlice'
import { setMetaData, addSavedFriend as addSavedFriendSlice, removeSavedFriend as removeSavedFriendSlice } from '../../containers/user/userSlice'
import store from '../../redux/store'
import { auth, db, messaging } from './base'

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const registerWithEmailAndPassword = async (handle, email, password) => {
  try {
    const q = query(collection(db, 'users'), where('handle', '==', handle))
    const docs = await getDocs(q)
    if (docs.empty) {
      const res = await createUserWithEmailAndPassword(auth, email, password)
      const { user } = res
      await sendEmailVerification(user)
      await updateProfile(user, {
        displayName: handle,
      })

      await setDoc(doc(db, 'users', user.uid), {
        uid: user.uid,
        handle,
        email,
      })
      store.dispatch(resetErrors())
    } else {
      store.dispatch(setHandleError({ error: true, errorMessage: 'Handle already taken' }))
    }
  } catch (error) {
    console.error('registerWithEmailAndPasswordError', error)
    store.dispatch(setEmailError({ error: true, errorMessage: 'Not a valid email address or already taken' }))
  }
}

export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

export const logout = () => {
  signOut(auth)
}

export const getToken = async (uid) => {
  try {
    const currentToken = await getFCMToken(messaging, { vapidKey: 'BKsdfelILrV177cqHq5g-vyDzJLkyT8KGSogqH5JisirB58odluY2O_RNbA0oNTb_CVwqOO3iEeery-OgU5C03o' })
    if (currentToken) {
      const tokenRef = doc(db, 'users', uid)
      await updateDoc(tokenRef, {
        token: currentToken,
      })
      console.log('current token for client: ', currentToken)
      // store.dispatch(setTokenFound(true))
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.')
      // store.dispatch(setTokenFound(false))
      // shows on the UI that permission is required
    }
  } catch (err) {
    console.error('An error occurred while retrieving token. ', err)
    // catch error while creating client token
  }
}

export const loadUserMetaData = async () => {
  try {
    const userRef = doc(db, 'users', auth.currentUser.uid)
    const docSnapshot = await getDoc(userRef)
    const metaData = docSnapshot.data()

    if (metaData.friends && metaData.friends.length) {
      const usersRef = collection(db, 'users')
      const querySnapshot = await getDocs(query(usersRef, where('uid', 'in', metaData.friends)))
      if (!querySnapshot.empty) {
        const friendsList = []
        querySnapshot.forEach((friend) => {
          const friendToAdd = {
            handle: friend.data().handle,
            uid: friend.data().uid,
            token: friend.data().token,
          }
          friendsList.push(friendToAdd)
        })

        metaData.friends = friendsList
      }
    }

    store.dispatch(setMetaData(metaData))
  } catch (error) {
    console.error('loadUserMetaData Error', error)
  }
}

export const addSavedFriend = async (friend) => {
  try {
    const userRef = doc(db, 'users', auth.currentUser.uid)
    await updateDoc(userRef, {
      friends: arrayUnion(friend.uid),
    })
    store.dispatch(addSavedFriendSlice(friend))
  } catch (error) {
    console.error('addSavedFriend Error', error)
  }
}

export const removeSavedFriend = async (friend) => {
  try {
    const userRef = doc(db, 'users', auth.currentUser.uid)
    await updateDoc(userRef, {
      friends: arrayRemove(friend.uid),
    })
    store.dispatch(removeSavedFriendSlice(friend.handle))
  } catch (error) {
    console.error('addSavedFriend Error', error)
  }
}
