/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  members: [],
  uid: null,
  name: null,
  isJoinPartyDialogOpen: false,
  invitationUid: null,
}

export const partySlice = createSlice({
  name: 'party',
  initialState,
  reducers: {
    createParty: (state, action) => {
      state.members = [action.payload.user]
      state.uid = action.payload.uid
      state.name = action.payload.name
    },
    joinParty: (state, action) => {
      const { partyUid, partyName } = action.payload
      state.uid = partyUid
      state.name = partyName
      state.isJoinPartyDialogOpen = false
    },
    leaveParty: (state) => {
      state.members = []
      state.uid = null
      state.name = null
      state.isJoinPartyDialogOpen = false
      state.invitationUid = null
    },
    updatePartyMembers: (state, action) => {
      state.members = action.payload
    },
    clearParty: (state) => {
      state.members = []
      state.uid = null
    },
    openJoinPartyDialog: (state, action) => {
      state.isJoinPartyDialogOpen = true
      state.invitationUid = action.payload.invitationUid
      state.name = action.payload.partyName
    },
    closeJoinPartyDialog: (state) => {
      state.isJoinPartyDialogOpen = false
      state.invitationUid = null
    },
  },
})

export const {
  createParty,
  joinParty,
  leaveParty,
  updatePartyMembers,
  clearParty,
  openJoinPartyDialog,
  closeJoinPartyDialog,
} = partySlice.actions

export const selectPartyMembers = (state) => state.party.members
export const selectPartyUid = (state) => state.party.uid
export const selectPartyName = (state) => state.party.name
export const selectInvitationUid = (state) => state.party.invitationUid
export const selectIsJoinPartyDialogOpen = (state) => state.party.isJoinPartyDialogOpen

export default partySlice.reducer
