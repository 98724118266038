import axios from 'axios'
import store from '../redux/store'
import { setSelectedPlayer } from '../containers/cockpit/playerSlice'
import { addOrUpdatePlayer } from './firebase/player'

const STAR_CITIZEN_BASE_URL = 'https://api.starcitizen-api.com/b35c6baedee2f3489d04cd71594d9576/v1/auto'
const FIREBASE_CLOUD_MESSAGING_URL = 'https://fcm.googleapis.com/fcm/send'
const FCM_TOKEN = 'key=AAAAvf_JTTE:APA91bGfTtOuoebU0ZcvI5fyypGgU43L7ZCfOYPGfy830pGvNN7y1BiYX2SBoI4L1R_PBT9sBSMRlE-jtnMAPaNwa4-iOQ653r-X4uqw_cHAgWQhyyOASqIHFdeK-NpVpWrTC1nuoEye'

export function getPlayerByHandle(playerHandle, updateSavedPlayer) {
  if (playerHandle) {
    axios.get(`${STAR_CITIZEN_BASE_URL}/user/${playerHandle}`).then(
      (result) => {
        if (result.data.data && !!Object.keys(result.data.data).length) {
          if (!result.data.data.profile.handle) {
            store.dispatch(setSelectedPlayer({
              error: 'No access to players data!',
            }))
          } else {
            const player = result.data.data
            store.dispatch(setSelectedPlayer(player))
            if (updateSavedPlayer) {
              addOrUpdatePlayer(player)
            }
          }
        } else {
          store.dispatch(setSelectedPlayer({
            error: 'No player found',
          }))
        }
      },
      (error) => {
        console.error('getPlayerByHandleError', error)
      },
    )
  } else {
    // ToDo
  }
}

export function sendPartyInvitation(friendUid, playerToken, partyName, partyUid) {
  if (friendUid && playerToken) {
    axios.post(FIREBASE_CLOUD_MESSAGING_URL, {
      to: playerToken,
      data: {
        title: 'Party Invitation',
        body: `You have been invited to party: >>${partyName}<<`,
        url: `${window.location.origin}/Cockpit/${friendUid}`,
        partyUid,
        partyName,
      },
    }, {
      headers: {
        Authorization: FCM_TOKEN,
        'Content-Type': 'application/json',
      },
    }).then(
      (result) => {
        console.log('Invitation sent', result)
      },
      (error) => {
        console.error('getPlayerByHandleError', error)
      },
    )
  } else {
    // ToDo
  }
}

export function sendPlayerToParty(recipientList, playerHandle, senderName) {
  if (recipientList && playerHandle && senderName) {
    axios.post(FIREBASE_CLOUD_MESSAGING_URL, {
      registration_ids: recipientList,
      data: {
        title: 'Player has been shared',
        body: `${senderName} has shared the following player with you: >>${playerHandle}<<`,
        url: `${window.location.origin}`,
        playerHandle,
      },
    }, {
      headers: {
        Authorization: FCM_TOKEN,
        'Content-Type': 'application/json',
      },
    }).then(
      (result) => {
        console.log('Player sent', result)
      },
      (error) => {
        console.error('sendPlayerToPartyError', error)
      },
    )
  } else {
    // ToDo
  }
}
