/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  foundFriends: [],
}

export const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setFoundFriend: (state, action) => {
      const filteredFoundFriends = state.foundFriends.filter((friend) => (friend.handle !== action.payload.handle))
      state.foundFriends = [...filteredFoundFriends, action.payload]
    },
    clearFoundFriends: (state) => {
      state.foundFriends = []
    },
  },
})

export const {
  setFoundFriend,
  clearFoundFriends,
} = friendsSlice.actions

export const selectFoundFriends = (state) => state.friends.foundFriends

export default friendsSlice.reducer
