import {
  arrayRemove, arrayUnion, deleteDoc, doc, getDoc, onSnapshot, setDoc, updateDoc,
} from 'firebase/firestore'
import {
  createParty as createPartySlice,
  joinParty as joinPartySlice,
  leaveParty,
  updatePartyMembers,
} from '../../containers/cockpit/partySlice'
import store from '../../redux/store'
import { db } from './base'

let partyUnsubscribe

export const unsubscribeToParty = (partyUid, member) => {
  const partyRef = doc(db, 'parties', `${partyUid}`)
  getDoc(partyRef).then((partyDocResult) => {
    partyUnsubscribe()
    store.dispatch(leaveParty())

    // Empty list, delete party
    if (partyDocResult.data() && partyDocResult.data().members && partyDocResult.data().members.length === 1) {
      deleteDoc(partyRef)
    } else {
      updateDoc(partyRef, {
        members: arrayRemove(member),
      })
    }
  }, (error) => {
    console.error(error)
  })
}

export const listenToParty = (partyUid, member) => {
  const partyRef = doc(db, 'parties', `${partyUid}`)

  partyUnsubscribe = onSnapshot(partyRef, (result) => {
    if (result.data() && result.data().members && result.data().members.length) {
      store.dispatch(updatePartyMembers(result.data().members))
    }
  }, (error) => {
    console.error(error)
  })

  // Detecting a page leave as a party leave
  window.onbeforeunload = () => {
    unsubscribeToParty(partyUid, member)
  }
}

export const createParty = async (partyName, user) => {
  try {
    const now = Date.now()
    await setDoc(doc(db, 'parties', `${now}`), {
      name: partyName,
      members: [user],
    })
    listenToParty(now, user)
    store.dispatch(createPartySlice({ uid: now, name: partyName, user }))
  } catch (error) {
    console.error(error)
  }
}

export const joinParty = async (partyUid, newMember, partyName) => {
  try {
    listenToParty(partyUid, newMember)
    const partyRef = doc(db, 'parties', `${partyUid}`)
    await updateDoc(partyRef, {
      members: arrayUnion(newMember),
    })
    store.dispatch(joinPartySlice({ partyUid, partyName }))
  } catch (error) {
    console.error(error)
  }
}
