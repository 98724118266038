import { Box, Container } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getPlayerByHandle, sendPlayerToParty } from '../../utils/api'
import { auth } from '../../utils/firebase/base'
import { findFriend } from '../../utils/firebase/friends'
import { createParty, joinParty } from '../../utils/firebase/party'
import { loadSavedPlayers } from '../../utils/firebase/player'
import { getToken, loadUserMetaData } from '../../utils/firebase/user'
import routes from '../routes'
import { selectMetaData } from '../user/userSlice'
import CockpitFriends from './CockpitFriends'
import CockpitPlayers from './CockpitPlayers'
import { selectFoundFriends } from './friendsSlice'
import JoinPartyDialog from './JoinPartyDialog'
import {
  closeJoinPartyDialog,
  openJoinPartyDialog,
  selectInvitationUid,
  selectIsJoinPartyDialogOpen,
  selectPartyMembers,
  selectPartyName,
  selectPartyUid,
} from './partySlice'
import PlayerInfo from './PlayerInfo'
import { selectSavedPlayers, selectSelectedPlayer } from './playerSlice'

function Cockpit() {
  const navigate = useNavigate()
  const [authUser, loading] = useAuthState(auth)
  const dispatch = useDispatch()
  const selectedPlayer = useSelector(selectSelectedPlayer)
  const savedPlayers = useSelector(selectSavedPlayers)
  const foundFriends = useSelector(selectFoundFriends)
  const userMetaData = useSelector(selectMetaData)
  const partyMembers = useSelector(selectPartyMembers)
  const partyUid = useSelector(selectPartyUid)
  const partyName = useSelector(selectPartyName)
  const invitationUid = useSelector(selectInvitationUid)
  const isJoinPartyDialogOpen = useSelector(selectIsJoinPartyDialogOpen)
  const isSelectedPlayerAvailable = !!selectedPlayer && !!Object.keys(selectedPlayer).length && !selectedPlayer.error
  const isSelectedPlayerSaved = isSelectedPlayerAvailable && !!savedPlayers[selectedPlayer.profile.handle]

  const openDialog = (dialogInvitationUid, dialogPartyName) => {
    dispatch(openJoinPartyDialog({ invitationUid: dialogInvitationUid, partyName: dialogPartyName }))
  }

  useEffect(() => {
    if (loading) {
      return
    }
    if (!authUser) {
      navigate(routes.login, { replace: true })
    }
    if (!authUser.emailVerified) {
      navigate(routes.emailNotVerified, { replace: true })
    }
    if (!Object.keys(savedPlayers).length) {
      loadUserMetaData()
      loadSavedPlayers()
      getToken(authUser.uid)

      navigator.serviceWorker.addEventListener('message', ({ data }) => {
        if (data && data.invitationUid) openDialog(data.invitationUid, data.partyName)
        if (data && data.data && data.data.partyUid) openDialog(data.data.partyUid, data.data.partyName)

        if (data && data.playerHandle) getPlayerByHandle(data.playerHandle)
        if (data && data.data && data.data.playerHandle) getPlayerByHandle(data.data.playerHandle)
      })
    }
  }, [authUser, loading, navigate, savedPlayers])

  const getUser = () => userMetaData && {
    uid: userMetaData.uid,
    token: userMetaData.token,
    handle: userMetaData.handle,
  }

  const handlePlayerSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const playerHandle = data.get('playerHandle')

    if (!playerHandle) {
      return
    }
    getPlayerByHandle(playerHandle)
  }

  const handleFriendSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const friendHandle = data.get('friendHandle')

    if (!friendHandle) {
      return
    }
    findFriend(friendHandle)
  }

  const handleCreatePartySubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const newPartyName = data.get('partyName')

    createParty(newPartyName, getUser())
  }

  const handleCloseDialog = () => {
    dispatch(closeJoinPartyDialog())
  }

  const handleAcceptInvitation = (newPartyUid, user, newPartyName) => {
    if (newPartyUid) {
      joinParty(newPartyUid, { handle: user.handle, uid: user.uid, token: user.token }, newPartyName)
    } else {
      alert('New Party yet created')
    }
  }

  const sharePlayer = (player) => {
    console.log('player', player)
    const recipientIds = partyMembers.map((member) => member.token)
    sendPlayerToParty(recipientIds, player.profile.handle, userMetaData.handle)
  }

  const commonBorderStyle = {
    borderColor: (theme) => theme.palette.primary.light,
    borderStyle: 'solid',
    borderWidth: 0,
    borderRadius: 4,
    padding: 3,
  }

  return (
    <Container maxWidth="false">
      <Box>
        <Grid container spacing={6}>
          <CockpitFriends
            party={{ uid: partyUid, members: partyMembers, name: partyName }}
            foundFriends={foundFriends}
            savedFriends={userMetaData && userMetaData.friends}
            handleFriendSubmit={handleFriendSubmit}
            handleCreatePartySubmit={handleCreatePartySubmit}
            commonBorderStyle={commonBorderStyle}
          />
          <PlayerInfo
            handlePlayerSubmit={handlePlayerSubmit}
            isSelectedPlayerSaved={isSelectedPlayerSaved}
            isSelectedPlayerAvailable={isSelectedPlayerAvailable}
            selectedPlayer={selectedPlayer}
            commonBorderStyle={commonBorderStyle}
            sharePlayer={sharePlayer}
          />
          <CockpitPlayers
            savedPlayers={savedPlayers}
            isSelectedPlayerAvailable={isSelectedPlayerAvailable}
            selectedPlayer={selectedPlayer}
            commonBorderStyle={commonBorderStyle}
          />
        </Grid>
        <JoinPartyDialog
          open={isJoinPartyDialogOpen}
          handleClose={handleCloseDialog}
          handleAccept={handleAcceptInvitation}
          partyName={partyName}
          invitationUid={invitationUid}
          user={userMetaData}
        />
      </Box>
    </Container>
  )
}

export default Cockpit
