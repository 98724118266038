import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { generatePath, Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Avatar, Box, Button, Container, Grid, Link, TextField, Typography,
} from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../utils/firebase/base'
import { registerWithEmailAndPassword } from '../../utils/firebase/user'
import routes from '../routes'
import {
  resetErrors,
  selectEmailError,
  selectHandleError,
  selectPasswordError,
  setEmailError, setHandleError,
  setPasswordError,
} from './registerSlice'

function Register() {
  const [user, loading] = useAuthState(auth)
  const dispatch = useDispatch()
  const emailError = useSelector(selectEmailError)
  const handleError = useSelector(selectHandleError)
  const passwordError = useSelector(selectPasswordError)
  const navigate = useNavigate()

  useEffect(() => {
    if (loading) return
    if (user) navigate(generatePath(routes.user, { uid: user.uid }), { replace: true })
  }, [user, loading, navigate])

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email')
    const password = data.get('password')
    const handle = data.get('handle')

    if (!email) { dispatch(setEmailError({ error: true, errorMessage: 'Email is mandatory' })) }
    if (!password) { dispatch(setPasswordError({ error: true, errorMessage: 'Password is mandatory' })) }
    if (!handle) { dispatch(setHandleError({ error: true, errorMessage: 'Handle is mandatory' })) }
    if (email && password && handle) {
      dispatch(resetErrors())
      registerWithEmailAndPassword(handle, email, password)
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                error={handleError.error}
                helperText={handleError.errorMessage}
                fullWidth
                id="handle"
                label="Handle"
                name="handle"
                autoComplete="nickname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={emailError.error}
                helperText={emailError.errorMessage}
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                error={passwordError.error}
                helperText={passwordError.errorMessage}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Register
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link component={RouterLink} to={routes.login} variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>

  // <div className="register">
  // <div className="register__container">
  // <input
  //       type="text"
  //       className="register__textBox"
  //       value={name}
  //       onChange={(e) => setName(e.target.value)}
  //       placeholder="Full Name"
  //     />
  //     <input
  //       type="text"
  //       className="register__textBox"
  //       value={email}
  //       onChange={(e) => setEmail(e.target.value)}
  //       placeholder="E-mail Address"
  //     />
  //     <input
  //       type="password"
  //       className="register__textBox"
  //       value={password}
  //       onChange={(e) => setPassword(e.target.value)}
  //       placeholder="Password"
  //     />
  //     <button className="register__btn" onClick={register}>
  //       Register
  //     </button>
  //     <button
  //       className="register__btn register__google"
  //       onClick={signInWithGoogle}
  //     >
  //       Register with Google
  //     </button>
  //     <div>
  //       Already have an account? <Link to="/">Login</Link> now.
  //     </div>
  //   </div>
  // </div>
  )
}

export default Register
