import { Box, Rating, Typography } from '@mui/material'
import React from 'react'

function PlayerInfoOrgaization({
  selectedPlayer,
}) {
  return (
    <Box sx={{ pb: 4 }}>
      <Typography
        variant="h5"
        component="h5"
        sx={{
          textTransform: 'uppercase',
          color: (theme) => theme.palette.primary.light,
        }}
      >
        MAIN ORGANIZATION
      </Typography>
      <Box>
        <Box
          component="img"
          alt={selectedPlayer.organization.name}
          src={selectedPlayer.organization.image}
        />
      </Box>
      <Typography
        component="p"
        sx={{
          fontSize: 20,
          pb: 2,
        }}
      >
        {selectedPlayer.organization.name}
      </Typography>
      <Typography
        component="p"
        sx={{
          color: (theme) => theme.palette.primary.light,
          fontSize: 12,
        }}
      >
        Rank
      </Typography>
      <Typography component="p" sx={{ pb: 2 }}>
        {selectedPlayer.organization.rank}
      </Typography>
      <Typography
        component="p"
        sx={{
          color: (theme) => theme.palette.primary.light,
          fontSize: 12,
        }}
      >
        Spectrum Identification (SID)
      </Typography>
      <Typography component="p" sx={{ pb: 2 }}>
        {selectedPlayer.organization.sid}
      </Typography>
      <Rating name="read-only" value={parseInt(selectedPlayer.organization.stars, 10)} readOnly />
    </Box>
  )
}

export default PlayerInfoOrgaization
