/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  playerHandleError: {
    error: false,
    errorMessage: '',
  },
  selectedPlayer: null,
  savedPlayers: {},
}

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    setPlayerHandleError: (state, action) => {
      state.playerHandleError = {
        error: action.payload.error,
        errorMessage: action.payload.errorMessage,
      }
    },
    resetErrors: (state) => {
      state.playerHandleError = {
        error: false,
        errorMessage: '',
      }
    },
    setSelectedPlayer: (state, action) => {
      state.selectedPlayer = action.payload
    },
    addOrUpdatePlayer: (state, action) => {
      const player = state.savedPlayers[action.payload.profile.handle]
      if (player) {
        state.savedPlayers[action.payload.profile.handle] = {
          ...player,
          affiliation: action.payload.affiliation,
          profile: action.payload.profile,
          organization: action.payload.organization,
        }
      } else {
        state.savedPlayers[action.payload.profile.handle] = action.payload
      }
    },
    setSavedPlayerListAfterLoading: (state, action) => {
      state.savedPlayers = {
        ...state.savedPlayers,
        ...action.payload,
      }
    },
    removePlayer: (state, action) => {
      const players = state.savedPlayers
      delete players[action.payload]
      state.savedPlayers = players
    },
  },
})

export const {
  setPlayerHandleError,
  resetErrors,
  setSelectedPlayer,
  addOrUpdatePlayer,
  removePlayer,
  setSavedPlayerListAfterLoading,
} = playerSlice.actions

export const selectPlayerHandleError = (state) => state.player.playerHandleError
export const selectSelectedPlayer = (state) => state.player.selectedPlayer
export const selectSavedPlayers = (state) => state.player.savedPlayers

export default playerSlice.reducer
